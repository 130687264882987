<template>
  <section class="box-height realtime">
    <el-row
      :span="24"
      class="body"
      style="margin-left: 0; margin-right: 0"
      :gutter="20"
    >
      <el-col
        :span="16"
        class="content"
        style="position: relative; padding: 0 !important"
      >
        <div
          style="
            background: #ffffff;
            box-sizing: border-box;
            border-radius: 3px;
          "
        >
          <div style="padding: 10px 15px 10px 20px" class="city">
            <div style="display: flex; width: 100%; color: #333333">
              <p class="totalPerformance">
                <span class="lineS" style="padding-left: 15px"
                  >Offer业绩<br /><span class="totalS">{{
                    titInfo.offerAchievement
                  }}</span>
                </span>
              </p>
              <p class="totalPerformance">
                <span class="lineS" style="border-left: 1px solid #ededed"
                  >到岗业绩<br /><span class="totalS">{{
                    titInfo.entryAchievement
                  }}</span>
                </span>
              </p>
              <p class="totalPerformance">
                <span class="lineS" style="border-left: 1px solid #ededed"
                  >开票业绩
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <template slot="content">
                      统计说明： <br />
                      按实际开票时间统计</template
                    >
                    <i
                      class="iconfont icon icon-wenhao-xiangsu"
                      style="color: #898989; font-size: 14px"
                    ></i>
                  </el-tooltip>
                  <br /><span class="totalS">{{
                    titInfo.invoiceAchievement
                  }}</span>
                </span>
              </p>
              <p class="totalPerformance">
                <span class="lineS" style="border-left: 1px solid #ededed"
                  >总回款
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <template slot="content">
                      统计说明： <br />
                      按实际回款时间统计</template
                    >
                    <i
                      class="iconfont icon icon-wenhao-xiangsu"
                      style="color: #898989; font-size: 14px"
                    ></i> </el-tooltip
                  ><br /><span class="totalS">{{
                    titInfo.accountAchievement
                  }}</span>
                </span>
              </p>
              <p class="totalPerformance">
                <span class="lineS" style="border-left: 1px solid #ededed"
                  >总进度 <br /><span class="totalS">{{
                    titInfo.completeStr
                  }}</span>
                </span>
              </p>
            </div>
          </div>
          <el-radio-group
            v-model="versionVal"
            style="
              margin-right: 15px;
              z-index: 99;
              position: absolute;
              right: 0;
            "
            size="mini"
            @change="belloAttachmentId"
          >
            <el-radio-button label="地区">按地区</el-radio-button>
            <el-radio-button label="团队">按团队</el-radio-button>
          </el-radio-group>
          <div
            class="leftchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="myEchart"
            id="myEchart"
          ></div>
        </div>
      </el-col>
      <el-col :span="8" style="padding-right: 0">
        <div
          class="content"
          style="background: white; position: relative; border-radius: 3px"
        >
          <div
            class="line rightchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="average"
            id="average"
          ></div>
          <el-radio-group
            class="more"
            v-model="averagetype"
            size="mini"
            @change="averageChange"
          >
            <el-radio-button label="地区">按地区</el-radio-button>
            <el-radio-button label="团队">按团队</el-radio-button>
          </el-radio-group>
        </div>
      </el-col>
      <el-col :span="8" style="padding-right: 0">
        <div
          class="content"
          style="
            margin: 10px 0px 10px;
            background: white;
            position: relative;
            border-radius: 3px;
          "
        >
          <div
            class="line rightchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="trends"
            id="trends"
          ></div>
        </div>
      </el-col>
    </el-row>
    <el-row class="main" style="margin-left: 0; margin-right: 0" :gutter="20">
      <el-col :span="6" style="padding: 0">
        <div
          class="content"
          style="background: white; position: relative; border-radius: 3px"
        >
          <div
            class="bar botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="clients"
            id="clients"
          ></div>
          <span class="more" @click="showMore('clients')">更多  &gt</span>
        </div></el-col
      >
      <el-col :span="6">
        <div
          class="content"
          style="background: white; position: relative; border-radius: 3px"
        >
          <div
            class="bar botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="consultant"
            id="consultant"
          ></div>
          <span class="more" @click="showMore('consultant')">更多  &gt</span>
        </div>
      </el-col>
      <el-col :span="6" class="customersChart" style="padding: 0">
        <div
          class="content"
          style="background: white; position: relative; border-radius: 3px"
        >
          <div
            class="pie botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="offers"
            id="offers"
          ></div>
          <el-radio-group
            class="more"
            v-model="offerstype"
            size="mini"
            @change="offerChange"
          >
            <el-radio-button label="地区">按地区</el-radio-button>
            <el-radio-button label="团队">按团队</el-radio-button>
          </el-radio-group>
        </div></el-col
      >
      <el-col :span="6" style="padding-right: 0">
        <div class="content" style="background: white; border-radius: 3px">
          <div
            class="pie botchart"
            :style="{ width: '100%', margin: '0 auto' }"
            ref="industry"
            id="industry"
          ></div>
        </div>
      </el-col>
    </el-row>
    <!-- 更多 -->
    <el-drawer
      :visible.sync="dialogVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        {{ titleName }}
      </div>
      <div class="drawerCon">
        <el-row>
          <el-col>
            <el-button size="mini" @click="downExp" :loading="btnLoading"
              >导出</el-button
            >
            <el-pagination
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
              style="padding-right: 0px"
            ></el-pagination>
          </el-col>
          <el-col>
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="ListData"
              class="tabBorder custor is-top"
            >
              <el-table-column
                v-if="exportType == 'consultant'"
                label="顾问"
                prop="name"
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="行业名称"
                prop="industryName"
                v-if="exportType == 'industry'"
              ></el-table-column>
              <el-table-column
                label="客户名称"
                v-if="exportType == 'industry' || exportType == 'clients'"
              >
                <template #default="scope">
                  <span
                    class="tabHref"
                    @click="toCustomer(scope.row.companyId)"
                    >{{
                      exportType == "clients"
                        ? scope.row.name
                        : scope.row.companyName
                    }}</span
                  >
                </template></el-table-column
              >
              <el-table-column
                :label="
                  exportType == 'clients'
                    ? '业绩金额'
                    : exportType == 'consultant'
                    ? '开票业绩额'
                    : '到岗业绩总额'
                "
                prop="achievement"
              >
                <template slot-scope="scope">
                  <span
                    class="tabHref"
                    @click="showInfo(scope.row)"
                    v-if="scope.row.achievement"
                    >{{ scope.row.achievement }}</span
                  >
                  <span v-else>-</span>
                </template></el-table-column
              >
              <el-table-column
                v-if="exportType == 'consultant'"
                label="排行"
                prop="order"
              ></el-table-column>
              <el-table-column
                label="业绩占比"
                width="160"
                prop="percent"
                v-if="exportType == 'clients'"
              >
                <template slot-scope="scope">
                  {{ scope.row.percent ? scope.row.percent + "%" : "-" }}
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="pagination.total > 10 ? false : true"
              v-if="pagination.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagination.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="pagination.pageSize"
              layout="total,prev, pager, next,sizes"
              :total="pagination.total"
              style="padding-right: 0px"
            ></el-pagination>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
    <!-- 详情 -->
    <el-drawer
      :visible.sync="dialogInfoVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      destroy-on-close
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        {{ InfotitleName }}
      </div>
      <div class="drawerCon">
        <el-row
          v-if="
            exportType == 'clients' ||
            exportType == 'consultant' ||
            exportType == 'offers' ||
            exportType == 'industry'
          "
        >
          <el-col>
            <el-button size="mini" @click="downInfoExp" :loading="btnLoading"
              >导出</el-button
            >
            <el-pagination
              :hide-on-single-page="page.total > 10 ? false : true"
              v-if="page.total > 0"
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="page.current"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="page.size"
              layout="total,prev, pager, next,sizes"
              :total="page.total"
              style="padding-right: 0px"
            ></el-pagination>
          </el-col>
          <el-col>
            <el-table
              :header-cell-style="{
                'background-color': '#f5f7fa',
              }"
              :data="InfoData"
              class="tabBorder custor is-top"
            >
              <el-table-column
                v-if="exportType == 'clients' || exportType == 'consultant'"
                :label="exportType == 'clients' ? '项目经理' : '顾问'"
                :prop="
                  exportType == 'consultant' ? 'nickName' : 'projectManager'
                "
                width="150"
              ></el-table-column>
              <el-table-column
                label="行业名称"
                prop="industryName"
                v-if="exportType == 'industry'"
              ></el-table-column>
              <el-table-column
                v-if="exportType == 'offers'"
                label="操作顾问"
                width="150"
                prop="operateUser"
              ></el-table-column>
              <el-table-column
                label="时间"
                width="150"
                :prop="
                  exportType == 'consultant'
                    ? 'lastModifiedTime'
                    : exportType == 'clients'
                    ? 'performanceDate'
                    : exportType == 'industry'
                    ? 'entryTime'
                    : 'offerDate'
                "
              ></el-table-column>
              <el-table-column
                v-if="exportType == 'clients' || exportType == 'consultant'"
                label="业绩金额"
                width="150"
                prop="achievement"
              ></el-table-column>
              <el-table-column
                label="业绩类型"
                v-if="exportType == 'consultant'"
                width="150"
                prop="roleName"
              ></el-table-column>
              <el-table-column
                label="分配比例"
                width="160"
                prop="allocatePercentStr"
                v-if="exportType == 'consultant'"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.allocatePercentStr
                      ? scope.row.allocatePercentStr
                      : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="客户名称"
                v-if="exportType == 'clients' || exportType == 'industry'"
              >
                <template #default="scope">
                  <span class="tabHref" @click="toCustomer(scope.row.id)">{{
                    scope.row.companyName
                  }}</span>
                </template></el-table-column
              >
              <el-table-column label="项目名称">
                <template #default="scope">
                  <router-link
                    class="tabHref"
                    target="_blank"
                    :to="{
                      path: '/project/details',
                      query: { id: scope.row.projectId },
                    }"
                    >{{ scope.row.projectName }}
                  </router-link>
                </template></el-table-column
              >
              <el-table-column
                label="项目职能"
                prop="projectFunction"
                v-if="exportType == 'industry'"
              ></el-table-column>
              <el-table-column label="候选人" width="120">
                <template #default="scope">
                  <span
                    class="tabHref"
                    @click="showDrawer(scope.row.resumeId)"
                    >{{ scope.row.resumeName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="成单顾问"
                prop="userNames"
                v-if="exportType == 'industry'"
              ></el-table-column>
              <el-table-column
                label="到岗业绩金额"
                prop="achievement"
                v-if="exportType == 'industry'"
              ></el-table-column>
            </el-table>
            <el-pagination
              :hide-on-single-page="page.total > 10 ? false : true"
              v-if="page.total > 0"
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="page.current"
              :page-sizes="[10, 20, 50, 100]"
              :pager-count="5"
              :page-size="page.size"
              layout="total,prev, pager, next,sizes"
              :total="page.total"
              style="padding-right: 0px"
            ></el-pagination>
          </el-col>
        </el-row>
        <performanceInfo
          v-if="
            exportType == 'offer' ||
            exportType == 'daogang' ||
            exportType == 'invoice' ||
            exportType == 'account'
          "
          :chartType="exportType"
          :emplyeeId="emplyeeId"
          :filter="filter"
          :real="true"
        ></performanceInfo>
      </div>
    </el-drawer>
    <!-- 人才 -->
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>
        人才
      </div>
      <div class="drawerCon">
        <resumeDetails
          v-if="resumeId"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="customerDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i class="drawIcon iconfont icon-kh_xq"></i>客户
      </div>
      <div class="drawerCon">
        <customerInfo :customId="customId"></customerInfo>
      </div>
    </el-drawer>
  </section>
</template>

<script>
import $ from "jquery";
import * as echarts from "echarts";
import {
  totalAchievement, //实时监控 实时业绩数据
  yearAchievementCompleteRate, //年度业绩情况图表数据
  userRank, //顾问业绩Top5图表数据
  companyRank, //客户产值Top5图表数据
  offerAnalysis, //Offer量分析图表数据
  averagePerformance, //平均单产图表数据
  Trend, //业绩趋势分析图表数据
  industryPerformance, //行业业绩分布图表
  exportuserRank, //导出顾问业绩
  userrankDetail, //顾问业绩详情
  exportuserRankDetail, //导出顾问业绩详情
  companyRankDetail, //客户到岗业绩详情
  exportcompanyRank, //导出客户到岗业绩排行
  exportcompanyRankDetail, //导出客户到岗业绩详情
  offerDetail, //Offer量详情
  exportofferRankDetail, //导出Offer量详情
  industryCompanyRrank, //行业客户到岗业绩排行
  industryCompanyDetail, //行业客户业绩详情
  exportIndustryCompanyDetail, //导出行业客户业绩详情
  exportIndustryCompanyRank, //导出行业客户到岗业绩排行
} from "../../api/api";
import resumeDetails from "../../components/resumeDetail";
import customerInfo from "../../components/customer/customerInfo";
import { ApiBaseUrl } from "../../api/http";
import performanceInfo from "../../components/report/performanceInfo";
export default {
  components: {
    resumeDetails,
    customerInfo,
    performanceInfo,
  },
  data() {
    return {
      versionVal: "地区", //实时业绩类型
      titInfo: {
        //实时业绩
        offerAchievement: null,
        entryAchievement: null,
        invoiceAchievement: null,
        accountAchievement: null,
      },
      msgInfo: {
        //业绩情况
        departmentName: [], //部门名称
        target: [], //业绩目标
        offerAchievement: [], //offer业绩
        entryAchievement: [], //到岗业绩
        invoiceAchievement: [], //开票业绩
        accountAchievement: [], //总回款
        completeRate: [], //达成率
        offerDifference: [], //Offer业绩差额
        entryDifference: [], //到岗业绩差额
        invoiceDifference: [], //开票业绩差额
        accountDifference: [], //总回款差额
      },
      year: new Date().getFullYear(),
      offerstype: "地区", //offer类型
      averagetype: "地区",
      offerName: [],
      offerList: [],
      averageList: {
        departmentName: [],
        performance: [],
      },
      trendName: [],
      trendList: {
        accountAchievement: [],
        entryAchievement: [],
        invoiceAchievement: [],
        offerAchievement: [],
      },
      industryPerformanceList: {
        data: [],
        name: [],
      },
      titleName: "",
      InfotitleName: "",
      dialogVisible: false,
      drawerVisible: false,
      dialogInfoVisible: false, //详情
      resumeId: null,
      projectId: null,
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      repeatResume: { type: "列表", valId: "" },
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      btnLoading: false,
      ListData: [],
      exportType: "",
      filter: {
        //查询条件
        time: [
          new Date().getFullYear() + "-01" + "-01",
          new Date().getFullYear() + "-12" + "-31",
        ],
        searchType: "地区",
        show: false, // 默认不展示停用数据
      },
      clientsData: [],
      consultantData: [],
      InfoData: [],
      baseUrl: ApiBaseUrl,
      searchData: {},
      MyParm: {},
      MyParm1: {},
      MyParm2: {},
      MyParm3: {},
      MyParm4: {},
      emplyeeId: null,
      industryName: null,
      customerDrawerVisible: false,
      customId: "",
    };
  },
  created() {
    // 实时业绩数据
    this.getmonitorAchievement();
    // 实时监控实时业绩数据
    this.gettotalAchievement();
    // 顾问业绩Top5图表数据
    this.getUserRank();
    // 客户产值Top5图表数据
    this.getCompanyRank();
    // Offer量分析图表数据
    this.getOfferAnalysis();
    // 平均单产图表数据
    this.getAveragePerformance();
    // 业绩趋势分析图表数据
    this.getTrend();
    // 行业业绩分布图表
    this.getIndustryPerformance();
  },
  watch: {
    drawerVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
    dialogVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  mounted() {
  },
  methods: {
    //业绩类型切换
    belloAttachmentId(val) {
      this.filter.searchType = val;
      if (val) {
        this.getmonitorAchievement(val);
      }
    },
    //offer类型切换
    offerChange(val) {
      if (val) {
        this.offerstype = val;
        this.getOfferAnalysis(val);
      }
    },
    //平均单产类型切换
    averageChange(val) {
      if (val) {
        this.averagetype = val;
        this.getAveragePerformance(val);
      }
    },
    //实时监控实时业绩数据
    gettotalAchievement() {
      totalAchievement().then((res) => {
        if (res.success) {
          this.titInfo = res.result;
        }
      });
    },
    //业绩情况图表数据
    getmonitorAchievement(val) {
      this.msgInfo = {
        departmentName: [], //部门名称
        target: [], //业绩目标
        offerAchievement: [], //offer业绩
        entryAchievement: [], //到岗业绩
        invoiceAchievement: [], //开票业绩
        accountAchievement: [], //总回款
        completeRate: [], //达成率
        offerDifference: [], //Offer业绩差额
        entryDifference: [], //到岗业绩差额
        invoiceDifference: [], //开票业绩差额
        accountDifference: [], //总回款差额
      };
      yearAchievementCompleteRate({ type: this.versionVal || val }).then(
        (res) => {
          if (res.success) {
            res.result.forEach((element) => {
              this.msgInfo.departmentName.push(element.departmentName); //部门名称
              this.msgInfo.target.push(element.target); //业绩目标
              this.msgInfo.offerAchievement.push(element.offerAchievement); //offer业绩
              this.msgInfo.entryAchievement.push(element.entryAchievement); //到岗业绩
              this.msgInfo.invoiceAchievement.push(element.invoiceAchievement); //开票业绩
              this.msgInfo.accountAchievement.push(element.accountAchievement); //总回款
              this.msgInfo.completeRate.push(element.completeRate); //达成率
              this.msgInfo.offerDifference.push(element.offerDifference); //Offer业绩差额
              this.msgInfo.entryDifference.push(element.entryDifference); //到岗业绩差额
              this.msgInfo.invoiceDifference.push(element.invoiceDifference); //开票业绩差额
              this.msgInfo.accountDifference.push(element.accountDifference); //总回款差额
              $.data(
                this.MyParm2,
                element.departmentName,
                element.departmentId
              );
            });
            this.chinaConfigure();
            this.drawIndustryPieChart();
          }
        }
      );
    },
    //业绩情况图表
    chinaConfigure() {
      let chartDom = document.getElementById("myEchart");
      let myChart = echarts.init(chartDom);
      let option;
      let that = this;
      let colors = [
        "#8da7f2",
        "#b589eb",
        "#92d671",
        "#f2c561",
        "#e86666",
        "#b7bee2",
      ];
      option = {
        color: colors,
        title: {
          text: "业绩情况（" + this.year + "）",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "15",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
          formatter: function (params) {
            let str = params[0].name;
            params.forEach((item, index) => {
              if (
                item.seriesName !== "差额_Offer业绩" &&
                item.seriesName !== "差额_到岗业绩" &&
                item.seriesName !== "差额_回款业绩"
              ) {
                str =
                  str +
                  "<br/>" +
                  item.marker +
                  item.seriesName +
                  " : " +
                  item.value;
              }
            });
            return str;
          },
        },
        toolbox: {
          show: true,
          right: "5%",
          feature: {
            saveAsImage: { show: true },
          },
        },
        grid: {
          right: "7%",
          top: "20%",
          left: "90",
          bottom: "15%",
        },
        legend: {
          data: [
            "业绩目标",
            "Offer业绩",
            "到岗业绩",
            "开票业绩",
            "回款业绩",
            // "差额",
            "达成率",
          ],
          top: "10%",
          itemWidth: 20,
          itemHeight: 12,
        },
        xAxis: [
          {
            type: "category",
            interval: 0,
            axisTick: {
              interval: 0,
            },
            axisLabel: {
              show: true,
              rotate: "0",
              textStyle: {
                fontSize: 12,
                color: "#999", //X轴文字颜色
              },
            },
            data: this.msgInfo.departmentName,
          },
        ],

        dataZoom: [
          {
            show: true,
            height: 16,
            left: 100,
            right: 100,
            xAxisIndex: [0],
            bottom: 25,
            start: 0,
            end: 30,
            handleSize: "110%",
            handleStyle: {
              color: "#eeeeee",
            },
            textStyle: {
              color: "#333333",
            },
            fillerColor: "#dddddd",
            borderColor: "#eeeeee",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            left: 100,
            right: 100,
            start: 0,
            end: 30,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "金额（元）",
            min: 0,
            position: "left",
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "达成率(%)",
            min: 0,
            position: "right",
            offset: 0,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#999",
              },
            },
            axisLabel: {
              formatter: "{value} %",
            },
          },
        ],
        series: [
          {
            name: "业绩目标",
            type: "bar",
            stack: "one",
            barWidth: "10",
            data: this.msgInfo.target,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "Offer业绩",
            type: "bar",
            stack: "two",
            barWidth: "10",
            data: this.msgInfo.offerAchievement,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "差额_Offer业绩",
            type: "bar",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            stack: "two",
            data: this.msgInfo.offerDifference,
          },
          {
            name: "到岗业绩",
            type: "bar",
            stack: "three",
            barWidth: "10",
            data: this.msgInfo.entryAchievement,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "差额_到岗业绩",
            type: "bar",
            stack: "three",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            data: this.msgInfo.entryDifference,
          },
          {
            name: "开票业绩",
            type: "bar",
            stack: "four",
            barWidth: "10",
            data: this.msgInfo.invoiceAchievement,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "开票业绩_差额",
            type: "bar",
            stack: "four",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            data: this.msgInfo.invoiceDifference,
          },
          {
            name: "回款业绩",
            type: "bar",
            barWidth: "10",
            stack: "five",
            data: this.msgInfo.accountAchievement,
            itemStyle: {
              normal: {
                borderRadius: [2, 2, 0, 0],
              },
            },
          },
          {
            name: "差额_回款业绩",
            type: "bar",
            stack: "five",
            itemStyle: {
              normal: {
                color: "rgba(237,167,167,0.3)",
                borderRadius: [2, 2, 0, 0],
              },
            },
            data: this.msgInfo.accountDifference,
          },
          {
            name: "达成率",
            type: "line",
            yAxisIndex: 1,
            data: this.msgInfo.completeRate,
            symbolSize: "5",
            symbol: "none",
          },
        ],
      };
      let dataSize = this.msgInfo.departmentName.length;
      if (dataSize < 100) {
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 40;
      }
      if (dataSize < 50) {
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 60;
      }
      if (dataSize < 20) {
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 80;
      }
      if (dataSize < 8) {
        option.dataZoom[0].show = false;
        option.dataZoom[0].start = 0;
        option.dataZoom[0].end = 100;
        option.grid.bottom = "8%";
      }
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
      myChart.on("click", function (param) {
        let id = null;
        let key = param.name; //key唯一
        id = $.data(that.MyParm2, key); //可以拿到id
        if (param.seriesIndex !== 0) {
          if (param.seriesIndex == 1 || param.seriesIndex == 2) {
            //Offer业绩
            that.exportType = "offer";
          } else if (param.seriesIndex == 3 || param.seriesIndex == 4) {
            //到岗业绩
            that.exportType = "daogang";
          } else if (param.seriesIndex == 5 || param.seriesIndex == 6) {
            //开票业绩
            that.exportType = "invoice";
          } else if (param.seriesIndex == 7 || param.seriesIndex == 8) {
            //回款业绩
            that.exportType = "account";
          }
          that.showInfo({ name: key, id: id });
        }
      });
    },
    // 平均单产图表数据
    getAveragePerformance(val) {
      averagePerformance({ type: val ? val : this.averagetype }).then((res) => {
        if (res.success) {
          let list = {
            departmentName: [],
            performance: [],
          };
          res.result.forEach((element) => {
            list.departmentName.push(element.departmentName);
            list.performance.push(element.performance);
          });
          this.averageList = list;
          this.drawAverage();
        }
      });
    },
    //各地区平均单产图表
    drawAverage() {
      var chartDom = document.getElementById("average");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "平均单产（" + this.year + "）",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "10",
          top: "15",
        },
        toolbox: {
          show: true,
          top: "45",
          right: "15",
          feature: {
            saveAsImage: {
              show: true,
              title: "保存",
              type: "png",
              lang: ["点击保存"],
            },
          },
          iconStyle: {
            borderColor: "#999999", //图形的描边颜色
          },
          emphasis: {
            //hover样式
            iconStyle: {
              borderColor: "#d13034",
              Color: "#d13034",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: this.averagetype == "地区" ? "20" : "3",
          top: "90",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
        },
        xAxis: {
          show: this.averagetype == "地区" ? true : false,
          type: "category",
          data: this.averageList.departmentName,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: 12,
            color: "#999",
            formatter: function (value) {
              if (value.length > 6) {
                return value.substring(0, 6) + "...";
              } else {
                return value;
              }
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
            fontSize: 12,
            color: "#999",
          },
          axisPointer: {
            snap: true,
          },
        },
        series: [
          {
            name: "平均单产",
            type: "line",
            smooth: true,
            data: this.averageList.performance,
            itemStyle: {
              color: "#b465f1",
            },
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //业绩趋势分析图表数据
    getTrend() {
      Trend().then((res) => {
        if (res.success) {
          let name = [];
          let data = {
            accountAchievement: [],
            entryAchievement: [],
            invoiceAchievement: [],
            offerAchievement: [],
          };
          res.result.forEach((element) => {
            name.push(element.monthName);
            data.accountAchievement.push(element.accountAchievement);
            data.entryAchievement.push(element.entryAchievement);
            data.invoiceAchievement.push(element.invoiceAchievement);
            data.offerAchievement.push(element.offerAchievement);
          });
          this.trendName = name;
          this.trendList = data;
          this.drawTrends();
        }
      });
    },
    //各地区业绩发展趋势图表
    drawTrends() {
      var chartDom = document.getElementById("trends");
      var myChart = echarts.init(chartDom);
      var option;
      var color = ["#e86666", "#f2c561", "#92d671", "#b68beb"];
      option = {
        color: color,
        title: {
          text: "业绩趋势分析（近12个月）",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "10",
          top: "15",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
        },
        legend: {
          data: ["回款业绩", "开票业绩", "到岗业绩", "offer业绩"],
          top: "18%",
          itemHeight: 10,
          pageIconSize: 10,
          itemWidth: 20,
          textStyle: {
            color: "#606266",
            lineHeight: 18,
          },
        },
        toolbox: {
          top: "12",
          right: "15",
          feature: {
            saveAsImage: {
              show: true,
              title: "保存",
              type: "png",
              lang: ["点击保存"],
            },
          },
          iconStyle: {
            borderColor: "#999999", //图形的描边颜色
          },
          emphasis: {
            //hover样式
            iconStyle: {
              borderColor: "#d13034",
              color: "#d13034",
            },
          },
        },
        grid: {
          left: "3%",
          top: "32%",
          right: "4%",
          bottom: "15",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisTick: {
              alignWithLabel: true,
              interval: 0,
            },
            axisLabel: {
              show: true,
              rotate: "50",
              textStyle: {
                fontSize: 12,
                color: "#999", //X轴文字颜色
              },
            },
            data: this.trendName,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                color: "#999", //X轴文字颜色
              },
            },
          },
        ],
        series: [
          {
            name: "回款业绩",
            type: "line",
            smooth: true,
            // stack: "总量",
            areaStyle: {
              origin: "start",
              opacity: 0.1,
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  0.9,
                  [
                    {
                      offset: 0,
                      color: "rgba(232,102,102,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(232,102,102,0)",
                    },
                  ],
                  false
                ),
              },
            },
            emphasis: {
              focus: "series",
            },
            data: this.trendList.accountAchievement,
          },
          {
            name: "开票业绩",
            type: "line",
            smooth: true,
            // stack: "总量",
            areaStyle: {
              color: "#f2c561",
              origin: "start",
              opacity: 0.1,
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  0.9,
                  [
                    {
                      offset: 0,
                      color: "rgba(242,197,97,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(242,197,97,0)",
                    },
                  ],
                  false
                ),
              },
            },
            emphasis: {
              focus: "series",
            },
            data: this.trendList.invoiceAchievement,
          },
          {
            name: "到岗业绩",
            type: "line",
            smooth: true,
            // stack: "总量",
            areaStyle: {
              color: "#92d671",
              origin: "start",
              opacity: 0.1,
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  0.9,
                  [
                    {
                      offset: 0,
                      color: "rgba(146,214,113,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(146,214,113,0)",
                    },
                  ],
                  false
                ),
              },
            },
            data: this.trendList.entryAchievement,
          },
          {
            name: "offer业绩",
            type: "line",
            smooth: true,
            // stack: "总量",
            areaStyle: {
              color: "#b68beb",
              origin: "start",
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  0.9,
                  [
                    {
                      offset: 0,
                      color: "rgba(182,139,235,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(182,139,235,0)",
                    },
                  ],
                  false
                ),
              },
            },
            data: this.trendList.offerAchievement,
          },
        ],
      };
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //客户到岗业绩Top5图表数据
    getCompanyRank() {
      companyRank().then((res) => {
        if (res.success) {
          let name = [];
          let data = [];
          if (res.result) {
            res.result.forEach((element, index) => {
              if (index < 5) {
                name.push(element.name);
                data.push(element.achievement);
                $.data(this.MyParm, element.name, element.id);
              }
            });
          }
          this.clientsData = res.result;
          this.drawBarChart(
            "clients",
            "客户到岗业绩Top5",
            "{value}",
            name,
            "到岗业绩",
            data
          );
        }
      });
    },
    //顾问业绩Top5图表数据
    getUserRank() {
      userRank().then((res) => {
        if (res.success) {
          let name = [];
          let data = [];
          res.result.forEach((element, index) => {
            if (index < 5) {
              name.push(element.name);
              data.push(element.achievement);
              $.data(this.MyParm1, element.name, element.id);
            }
          });
          this.consultantData = res.result;
          this.drawBarChart(
            "consultant",
            "顾问业绩Top5（" + this.year + "）",
            "{value}",
            name,
            "交付额（元）",
            data
          );
        }
      });
    },
    //顾问业绩、客户产值图表
    drawBarChart(id, title, xFormat, ydata, sName, sData) {
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      var option;
      let that = this;
      option = {
        title: {
          text: title,
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "0",
          top: "0",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: {
            color: "#fff",
          },
          backgroundColor: "rgba(51,51,51,0.95)",
          borderWidth: "0",
        },
        grid: {
          top: "18%",
          left: "0",
          right: "5%",
          bottom: "0",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            formatter: xFormat,
            rotate: 25,
            color: "#999",
          },
          axisLine: {
            show: false,
          },
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          inverse: true, //倒叙
          scale: false,
          data: ydata,
          axisLabel: {
            interval: 0,
            fontSize: 12,
            width: 60,
            color: "#999",
            formatter: function (value) {
              if (value.length > 4) {
                return value.substring(0, 4) + "...";
              } else {
                return value;
              }
            },
          },
          nameTextStyle: {
            fontSize: 10,
            overflow: "truncate",
            width: 60,
          },
        },
        series: [
          {
            name: sName,
            type: "bar",
            barWidth: "10",
            itemStyle: {
              normal: {
                borderRadius: [0, 2, 2, 0],
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  1,
                  0,
                  [
                    {
                      offset: 0,
                      color: "#f65a5a", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#fda18a", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            data: sData,
          },
        ],
      };
      myChart.on("click", function (param) {
        let name = null;
        let key = param.name; //key唯一
        if (id == "clients") {
          name = $.data(that.MyParm, key); //可以拿到id
          that.exportType = "clients";
        } else if (id == "consultant") {
          name = $.data(that.MyParm1, key); //可以拿到id
          that.exportType = "consultant";
        }
        that.showInfo({ name: param.name, id: name });
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //Offer量分析图表数据
    getOfferAnalysis(val) {
      let name = [];
      let list = [];
      offerAnalysis({ type: val ? val : this.offerstype }).then((res) => {
        if (res.success) {
          res.result = res.result
            .sort((a, b) => a.percent - b.percent)
            .reverse();
          res.result.forEach((element) => {
            name.push(element.departmentName);
            let item = {
              value: element.count,
              name: element.departmentName,
              percent: element.percent,
            };
            list.push(item);
            $.data(this.MyParm3, element.departmentName, element.departmentId);
          });
          this.offerName = name;
          this.offerList = list;
          this.drawPieChart();
        }
      });
    },
    //各地区Offer量分析图表
    drawPieChart() {
      var chartDom = document.getElementById("offers");
      var myChart = echarts.init(chartDom);
      var option;
      var that = this;
      var seriesData = this.offerList;
      option = {
        color: [
          "#e86666",
          "#fe8943",
          "#f2c561",
          "#92d671",
          "#37c2a4",
          "#6b8be9",
          "#4f64cd",
          "#6c65e7",
          "#a270f0",
          "#b589eb",
        ],
        title: {
          text: "Offer量分析（" + this.year + "）",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "0",
          top: "0",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          data: this.offerName,
          height: "60%",
          right: "10",
          top: "30%",
          icon: "circle",
          textStyle: {
            width: 80,
          },
          formatter: function (name) {
            if (seriesData.length) {
              const item = seriesData.filter((item) => item.name === name)[0];
              if (name.length > 5) {
                name = name.substr(0, 3) + "...";
              }
              let label = name + " " + item.percent + "%";
              return label;
            }
          },
        },
        series: [
          {
            name: "交付量",
            type: "pie",
            radius: ["35%", "60%"],
            center: ["30%", "60%"],
            data: this.offerList,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              normal: {
                show: false,
                position: "center",
                formatter: "{value|{c}}\n{label|{b}}",
                rich: {
                  value: {
                    padding: 5,
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: 18,
                  },
                  label: {
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: 12,
                  },
                },
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.on("click", function (param) {
        let name = null;
        let key = param.name; //key唯一
        name = $.data(that.MyParm3, key); //可以拿到id
        that.exportType = "offers";
        that.showInfo({ name: param.name, id: name });
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    //行业到岗业绩分析图表数据
    getIndustryPerformance() {
      let name = [];
      let list = [];
      industryPerformance().then((res) => {
        if (res.success) {
          res.result = res.result
            .sort((a, b) => a.percent - b.percent)
            .reverse();
          res.result.forEach((element) => {
            name.push(element.name);
            let item = {
              value: element.achievement,
              name: element.name,
              percent: element.percent,
            };
            list.push(item);
            $.data(this.MyParm4, element.name, element.name);
          });
          this.industryPerformanceList.data = list;
          this.industryPerformanceList.name = name;
          this.drawIndustryPieChart();
        }
      });
    },
    //行业到岗业绩分析图表
    drawIndustryPieChart() {
      var chartDom = document.getElementById("industry");
      var myChart = echarts.init(chartDom);
      var option;
      var that = this;
      var seriesData = this.industryPerformanceList.data;
      option = {
        color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
        ],
        title: {
          text: "行业到岗业绩分析（" + this.year + "）",
          textStyle: {
            fontSize: 16,
            color: "#333",
          },
          left: "0",
          top: "0",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          data: this.industryPerformanceList.name,
          height: "60%",
          right: "10",
          top: "30%",
          icon: "circle",
          textStyle: {
            width: 80,
          },
          formatter: function (name) {
            if (seriesData.length) {
              const item = seriesData.filter((item) => item.name === name)[0];
              if (name.length > 5) {
                name = name.substr(0, 3) + "...";
              }
              let label = name + " " + item.percent + "%";
              return label;
            }
          },
        },
        series: [
          {
            name: "业绩额",
            type: "pie",
            radius: ["35%", "60%"],
            center: ["30%", "60%"],
            data: this.industryPerformanceList.data,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              normal: {
                show: false,
                position: "center",
                formatter: "{value|{c}}\n{label|{b}}",
                rich: {
                  value: {
                    padding: 5,
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: 18,
                  },
                  label: {
                    align: "center",
                    verticalAlign: "middle",
                    fontSize: 12,
                  },
                },
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.on("click", function (param) {
        let name = null;
        let key = param.name; //key唯一
        name = $.data(that.MyParm4, key); //可以拿到id
        that.exportType = "industry";
        that.showMore("industry", name);
      });
      window.addEventListener("resize", () => {
        myChart.resize();
      });
      option && myChart.setOption(option);
    },
    // 更多
    showMore(type, name) {
      this.exportType = type;
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.ListData = [];
      switch (type) {
        case "clients":
          this.titleName = "客户产值";
          this.ListData = this.clientsData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          this.pagination.total = this.clientsData.length;
          break;
        case "consultant":
          this.titleName =
            "开票业绩排行（" +
            this.filter.time[0].substring(0, 7) +
            "至" +
            this.filter.time[1].substring(0, 7) +
            "）";
          this.ListData = this.consultantData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          this.pagination.total = this.consultantData.length;
          break;
        case "industry":
          this.titleName =
            "行业业绩详情（" + this.filter.time[0].substring(0, 4) + "）";
          this.industryName = name;
          this.loadDataindustry(name);
          break;
      }
      this.dialogVisible = true;
    },
    loadDataindustry() {
      let data = {
        industryName: this.industryName,
        page: {
          current: this.pagination.pageNo,
          size: this.pagination.pageSize,
        },
      };
      industryCompanyRrank(data).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        }
      });
    },
    // 导出
    downExp() {
      //导出
      this.btnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      switch (this.exportType) {
        case "consultant":
          exportuserRank().then((res) => {
            if (res.success) {
              if (!setData) {
                isconfirm = false;
                location.href = this.baseUrl + res.result;
              }
            }
            this.btnLoading = false;
          });
          break;
        case "clients":
          exportcompanyRank().then((res) => {
            if (res.success) {
              if (!setData) {
                isconfirm = false;
                location.href = this.baseUrl + res.result;
              }
            }
            this.btnLoading = false;
          });
          break;
        case "industry":
          let data = {
            industryName: this.industryName,
            page: {
              current: this.pagination.pageNo,
              size: this.pagination.pageSize,
            },
          };
          exportIndustryCompanyRank(data).then((res) => {
            if (res.success) {
              if (!setData) {
                isconfirm = false;
                location.href = this.baseUrl + res.result;
              }
            }
            this.btnLoading = false;
          });
          break;
      }
    },
    //切换页数
    handleCurrentChange(val) {
      $(".el-drawer__body").scrollTop(0);
      this.pagination.pageNo = val;
      switch (this.exportType) {
        case "clients":
          this.ListData = this.clientsData.slice(
            Number((this.pagination.pageNo - 1) * this.pagination.pageSize),
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "consultant":
          this.ListData = this.consultantData.slice(
            Number((this.pagination.pageNo - 1) * this.pagination.pageSize),
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "industry":
          this.loadDataindustry();
          break;
      }
    },
    // 切换数量
    handleSizeChange(val) {
      $(".el-drawer__body").scrollTop(0);
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      switch (this.exportType) {
        case "clients":
          this.ListData = this.clientsData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "consultant":
          this.ListData = this.consultantData.slice(
            0,
            Number(this.pagination.pageNo * this.pagination.pageSize)
          );
          break;
        case "industry":
          this.loadDataindustry();
          break;
      }
    },
    // 详情显示
    showInfo(row) {
      this.emplyeeId = row.id;
      this.InfotitleName =
        "业绩详情（" +
        this.filter.time[0].substring(0, 7) +
        "至" +
        this.filter.time[1].substring(0, 7) +
        " " +
        row.name +
        "）";
      if (this.exportType == "clients") {
        this.loadDetailconsultant();
      } else if (this.exportType == "consultant") {
        this.loadDetailclients();
      } else if (this.exportType == "offers") {
        this.InfotitleName = "Offer详情";
        this.loadDetailoffers();
      } else if (this.exportType == "offer") {
        this.InfotitleName = "Offer业绩详情";
      } else if (this.exportType == "daogang") {
        this.InfotitleName = "到岗业绩详情";
      } else if (this.exportType == "invoice") {
        this.InfotitleName = "开票业绩详情";
      } else if (this.exportType == "account") {
        this.InfotitleName = "回款业绩详情";
      } else if (this.exportType == "industry") {
        this.InfotitleName =
          "行业业绩详情（" + this.filter.time[0].substring(0, 4) + "）";
        this.emplyeeId = row.companyId;
        this.loadDetailindustry();
      }
      this.dialogInfoVisible = true;
    },
    loadDetailclients() {
      let data = {
        searchId: this.emplyeeId,
        page: {
          current: this.page.current,
          size: this.page.size,
        },
      };
      userrankDetail(data).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
          this.listLoading1 = false;
        }
      });
    },
    loadDetailconsultant() {
      let data = {
        searchId: this.emplyeeId,
        page: {
          current: this.page.current,
          size: this.page.size,
        },
      };
      companyRankDetail(data).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
          this.listLoading1 = false;
        }
      });
    },
    loadDetailoffers() {
      let data = {
        searchId: this.emplyeeId,
        page: {
          current: this.page.current,
          size: this.page.size,
        },
      };
      offerDetail(data).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
          this.listLoading1 = false;
        }
      });
    },
    loadDetailindustry() {
      let data = {
        industryName: this.industryName,
        companyId: this.emplyeeId,
        page: {
          current: this.page.current,
          size: this.page.size,
        },
      };
      industryCompanyDetail(data).then((res) => {
        if (res.success) {
          this.InfoData = res.result.records;
          this.page.total = res.result.total;
          this.listLoading1 = false;
        }
      });
    },
    //详情导出
    downInfoExp() {
      this.btnLoading = true;
      let setData = false;
      let isconfirm = true;
      var int = setTimeout(function () {
        let that = this;
        setData = true;
        clearInterval(int);
        let div =
          "<p style='font-weight:bold'>后台处理中，稍后请在 " +
          "<a href='/tools/exportingRecords' style='color:#526ecc'>导出记录</a>" +
          " 获取文件</p>";
        if (isconfirm) {
          that.$globalHub
            .$confirm(div, "提示", {
              showCancelButton: false,
              showConfirmButton: false,
              customClass: "altCls",
              dangerouslyUseHTMLString: true,
            })
            .then(() => {})
            .catch(() => {});
        }
      }, 5000);
      if (this.exportType == "clients") {
        exportcompanyRankDetail({
          page: {
            current: this.page.current,
            size: this.page.size,
          },
          searchId: this.emplyeeId,
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      } else if (this.exportType == "consultant") {
        exportuserRankDetail({
          page: {
            current: this.page.current,
            size: this.page.size,
          },
          searchId: this.emplyeeId,
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      } else if (this.exportType == "offers") {
        exportofferRankDetail({
          page: {
            current: this.page.current,
            size: this.page.size,
          },
          searchId: this.emplyeeId,
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      } else if (this.exportType == "industry") {
        exportIndustryCompanyDetail({
          industryName: this.industryName,
          companyId: this.emplyeeId,
          page: {
            current: this.page.current,
            size: this.page.size,
          },
        }).then((res) => {
          if (res.success) {
            if (!setData) {
              isconfirm = false;
              location.href = this.baseUrl + res.result;
            }
          }
          this.btnLoading = false;
        });
      }
    },
    handleCurrentChange1(val) {
      $(".el-drawer__body").scrollTop(0);
      this.page.current = val;
      switch (this.exportType) {
        case "clients":
          this.loadDetailconsultant();
          break;
        case "consultant":
          this.loadDetailclients();
          break;
        case "offers":
          this.loadDetailoffers();
          break;
        case "industry":
          this.loadDetailindustry();
          break;
      }
    },
    handleSizeChange1(val) {
      $(".el-drawer__body").scrollTop(0);
      //pageSize 改变时会触发
      this.page.size = val;
      this.page.current = 1;
      switch (this.exportType) {
        case "clients":
          this.loadDetailconsultant();
          break;
        case "consultant":
          this.loadDetailclients();
          break;
        case "offers":
          this.loadDetailoffers();
          break;
        case "industry":
          this.loadDetailindustry();
          break;
      }
    },
    //关闭抽屉
    handleClose1() {
      this.dialogInfoVisible = false;
      this.page = {
        current: 1,
        size: 10,
        total: 0,
      };
    },
    //关闭抽屉
    handleClose() {
      this.drawerVisible = false;
      this.customerDrawerVisible = false;
    },
    //弹出抽屉
    showDrawer(id) {
      this.resumeId = id;
      this.drawerVisible = true;
    },
    //客户详情
    toCustomer(comId) {
      this.customerDrawerVisible = true;
      this.customId = comId;
    },
  },
};
</script>

<style scoped lang='less'>
.title {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  background-color: #fff;
  padding: 16px 20px 14px 20px;
}
.body {
  div {
    box-sizing: border-box;
  }
}
.chart-container {
  width: 100%;
  float: left;
}
.more {
  right: 15px;
  position: absolute;
  top: 15px;
  color: #999;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: #526ecc;
    text-decoration: underline;
  }
}
.leftchart {
  height: 465px;
  @media (max-width: 1366px) {
    height: 385px;
  }
}
.rightchart {
  height: 285px;
  @media (max-width: 1366px) {
    height: 245px;
  }
}
.botchart {
  height: 260px;
  @media (max-width: 1366px) {
    height: 150px;
  }
}
.content {
  border: 1px #e5e5e5 solid;
  &:hover {
    -webkit-box-shadow: 0 3px 8px 1px #e0e0e0;
    box-shadow: 0 3px 8px 1px #e0e0e0;
  }
}
</style>
<style lang='less'>
.realtime {
  padding: 0;
  margin: 0 15px;
  .city {
    display: flex;
    justify-content: space-between;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #e60012;
    background-color: #fff;
  }
  .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 10px;
  }
  .el-progress-bar__inner {
    background-image: linear-gradient(-90deg, #2d76f0 0%, #50c0ff 100%),
      linear-gradient(#eaeaea, #eaeaea);
    background-blend-mode: normal, normal;
  }
  .el-progress__text {
    display: none;
  }
  .el-progress-bar {
    padding: 0;
  }
  .main {
    .itemStyle {
      overflow: hidden;
      margin-bottom: 8px;
    }
    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      margin-right: 8px;
      vertical-align: middle;
    }
    .el-col-8 {
      margin-bottom: 20px;
    }
    .el-col-6 {
      margin-bottom: 15px;

      > div {
        background: #ffffff;
        min-height: 318px;
        box-sizing: border-box;
        padding: 18px;
        @media (max-width: 1366px) {
          min-height: 150px;
        }
        .el-col-12 {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
<style scoped>
.font-w {
  font-weight: 600;
  font-size: 18px;
}
.totalPerformance {
  flex: 1;
  margin-bottom: 5px;
  /* margin-top: 10px; */
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
  line-height: 60px;
  border-bottom: 1px #e5e5e5 solid;
}
.lineS {
  display: inline-block;
  font-size: 13px;
  padding-left: 15px;
  line-height: 23px;
  transform: translate(10px, 10px);
  width: 99%;
  margin-bottom: 15px;
  color: #606266;
}
.totalS {
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
  color: #333333;
}
</style>