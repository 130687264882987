import { render, staticRenderFns } from "./realtime.vue?vue&type=template&id=33d4d674&scoped=true&"
import script from "./realtime.vue?vue&type=script&lang=js&"
export * from "./realtime.vue?vue&type=script&lang=js&"
import style0 from "./realtime.vue?vue&type=style&index=0&id=33d4d674&prod&scoped=true&lang=less&"
import style1 from "./realtime.vue?vue&type=style&index=1&id=33d4d674&prod&lang=less&"
import style2 from "./realtime.vue?vue&type=style&index=2&id=33d4d674&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d4d674",
  null
  
)

export default component.exports